import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Snackbar,
  TextField,
} from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import styled from '@emotion/styled/macro';
import { TestModeContext } from 'src/context/testModeContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageCard from 'src/components/reusableComponents/ImageCard';
import http from 'src/utils/api';
import { formatData, formatValidationResult } from 'src/components/testMode/helperDni';
import {
  formatDataCedula,
  formatValidationResultCedula,
} from 'src/components/testMode/helperCedula';
import api from 'src/components/testMode/data';

const GridStyled = styled(Grid)`
  @media (max-width: 900px) {
    padding: 0 80px;
  }
  @media (max-width: 500px) {
    padding: 0px;
  }
`;

const TextFieldStyled = styled(TextField)`
  .MuiInputBase-input {
    background-color: #fff;
    border-radius: 4px;
  }
`;

const TestMode = () => {
  const { infoTestMode, setInfoTestMode } = TestModeContext();
  const { images } = infoTestMode;
  const [idOperacion, setIdOperacion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCedulaValidation, setIsCedulaValidation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getCountry = () => {
      const country = localStorage.getItem('country');
      if (country === 'COL') {
        setIsCedulaValidation(true);
      }
    };
    getCountry();
  }, []);

  const handleResult = async () => {
    if (!idOperacion) {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Debe ingresar un ID de Operación para poder continuar',
      });
      return;
    }
    if (images.filter((image) => image.src !== '').length < 2) {
      setIsOpen({
        open: true,
        severity: 'error',
        message: isCedulaValidation
          ? 'Debe ingresar al menos una imagen de la CÉDULA FRENTE y CÉDULA DORSO para poder continuar'
          : 'Debe ingresar al menos una imagen del DNI FRENTE y DNI DORSO para poder continuar',
      });
      return;
    }
    const headerConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const data = isCedulaValidation
      ? formatDataCedula(idOperacion, infoTestMode)
      : formatData(idOperacion, infoTestMode);
    try {
      setIsLoading(true);
      const response = isCedulaValidation
        ? api
        : await http.post('api/validar/', data, headerConfig);
      const validationResult = isCedulaValidation
        ? formatValidationResultCedula(response, infoTestMode)
        : formatValidationResult(response, infoTestMode);
      validationResult && setInfoTestMode(validationResult);
      navigate('/app/details', {
        state: { testMode: true, isCedulaValidation: isCedulaValidation },
      });
    } catch (error) {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Ha ocurrido un inconveniente.',
      });
      setIdOperacion('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
        <Container maxWidth={false} disableGutters>
          <Box>
            <GridStyled container spacing={3}>
              <Grid item xs={12}>
                <TextFieldStyled
                  id="id_op"
                  name="id_op"
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder="ID Operación"
                  inputProps={{
                    min: 0,
                  }}
                  value={idOperacion}
                  onChange={(e) => setIdOperacion(e.target.value)}
                />
              </Grid>
              {images.map((image) => (
                <Grid item key={image.id} md={5} xs={12}>
                  <ImageCard
                    imageData={image}
                    accept={'image/*'}
                    contextData={infoTestMode}
                    setContextData={setInfoTestMode}
                    isPolicyValidation={false}
                    isTemplate={false}
                  />
                </Grid>
              ))}
              <Grid container item md={10} xs={12} justifyContent="flex-end">
                <Box mt={2}>
                  <Button endIcon={<ArrowRight />} variant="contained" onClick={handleResult}>
                    siguiente
                  </Button>
                </Box>
              </Grid>
            </GridStyled>
          </Box>
        </Container>
      </Box>
      <Dialog open={isLoading}>
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'center', px: 15, pt: 6, fontSize: '30px' }}
        >
          Validando identidad
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', px: 15, pb: 6 }}>
          <CircularProgress size={25} />
        </DialogContent>
      </Dialog>
      {isOpen.open && (
        <Snackbar
          open={isOpen.open}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleClose}
        >
          <Alert
            severity={isOpen.severity}
            variant="filled"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default TestMode;
